import React from "react";
import "../styles/welcome.scss";

function Welcome() {
  return (
    <>
      <div className="welcome-container" id="welcome"></div>
    </>
  );
}

export default Welcome;
